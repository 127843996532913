import React from 'react';
import './index.css'
import './App.css'
import NavBar from './Components/NavBar';
import { HeroSection } from './Components/Sections/HeroSection';
import { FeaturesSection } from './Components/Sections/FeaturesSection';
import Footer from './Components/Footer';
import { SubscribeSection } from './Components/Sections/SubscribeSection';

function App() {
  const [width, setWidth] = React.useState(window.innerWidth || 0);
  React.useEffect(() => {
    return window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });
}, []);

  return (
    <div 
      className='relative flex justify-center overflow-hidden'
    >
      <div 
        className="App-container flex flex-col text-slate-900 px-4"
      >
        <NavBar/>
        <HeroSection/>
        <FeaturesSection/>
        <SubscribeSection width={width}/>
        <Footer/>
      </div>
    </div>
  );
}

export default App;
