import React from 'react';
import { Logo } from './Logo';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import { EmailSubscribeStatus, MAILCHIMP_API_ENDPOINT } from '../Constants';

const noop = () => null;

function EmailSubscribe({
    subscribe,
    status: inputStatus,
    message,
}: {
    subscribe: (params: {EMAIL: string}) => void,
    status: EmailSubscribeStatus,
    message: string | Error | null,
}) {
    const [status, setStatus] = React.useState<EmailSubscribeStatus>(inputStatus);
    const emailInputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        setStatus(inputStatus);
    }, [inputStatus]);

    const buttonClassName = 
            status === 'error' 
                ? 'bg-red-500 hover:bg-red-600 border-red-500 focus:border-red-600' 
                : (status === 'success' ? 'bg-green-600 hover:bg-green-700 border-green-600 focus:border-green-700' : 'bg-blue-500 hover:bg-blue-600 border-blue-500 focus:border-blue-600');
    const buttonText = 
        status === 'error'
            ? (typeof(message) === 'string' ? message : 'Invalid email')
            : (status === 'success' ? 'Subscribed' : (status === 'sending' ? 'Sending ...' : 'Get early access'));

    return (
        <div
            className='flex-row justify-center items-center px-8'
        >
            <input 
                ref={emailInputRef}
                type="email"
                className='bg-slate-100 px-4 py-2 text-slate-500 rounded-xl focus:outline-none border-2 border-slate-100 focus:border-blue-500 transition-colors'
                placeholder='Your email here.'
                onChange={() => setStatus(null)}
                onKeyDown={(e) => {
                    if (e.key?.toLowerCase() === 'enter' && status !== 'success' && emailInputRef.current?.value) {
                        subscribe({
                            EMAIL: emailInputRef.current.value,
                        });
                    }
                }}
            />
            <button 
                className={`${buttonClassName} cursor-pointer text-white px-4 py-2 ml-4 rounded-xl transition-colors border-2  focus:outline-none`}
                onClick={() => {
                    if (status !== 'success' && emailInputRef.current?.value) {
                        subscribe({
                            EMAIL: emailInputRef.current.value,
                        });
                    }
                }}
            >{buttonText}</button>
        </div>
    )
}

export default function NavBar() {
    return (
        <div className='flex flex-row items-center justify-center w-full py-4 text-sm md:text-base z-50'>
            <div className='flex-none md:mx-12 my-4 h-5 md:h-7'>
                <Logo/>
            </div>
            <div className='flex-1'>
                
            </div>
            <div className='hidden md:block'>
                <MailchimpSubscribe
                        url={MAILCHIMP_API_ENDPOINT}
                        render={({subscribe, status, message}) => <EmailSubscribe subscribe={subscribe} status={status} message={message}/>}
                    />
            </div>
            <button 
                className='block md:hidden rounded-lg text-lg text-white bg-blue-500 px-4 py-2 shadow-md'
                onClick={() => {
                    document.getElementById('Subscribe')?.scrollIntoView({behavior: 'smooth'});
                }}
            >
                Get early access
            </button>
        </div>
    )
}