import React from 'react';

function BaseFeatureSection({
    title,
    description,
    iconUrl,
    imageUrl,
    marginTop='5vh'
}: {
    title: string,
    description: string,
    iconUrl?: string,
    imageUrl?: string,
    marginTop?: string,
}) {
    return (
        <div 
            className='flex flex-col items-center md:flex-row md:bg-slate-50 md:border md:border-slate-100 md:rounded-3xl md:py-12 md:px-12 mb-20 md:mb-[5vh]'
            style={{
                marginTop,
            }}
        >
            <div className='order-2 md:order-1 md:w-2/6 md:h-full flex flex-col justify-center pr-12'>
                {iconUrl ? <img alt="drag_drop" className='hidden md:block w-32' src={iconUrl}/> : <div className='w-32 h-32 rounded-md bg-white'></div>}
                <div className='text-4xl leading-normal font-bold mt-4 md:mt-12 mr-2'>
                    {title}
                </div>
                <div className='mt-4 md:mt-12 text-lg'>
                    {description}
                </div>
            </div>
            <div className='order-1 md:order-2 h-4/6 md:h-full md:w-4/6 mb-8 md:mb-0 flex justify-center items-center'>
                {imageUrl && <img alt={title} className="w-full rounded-3xl shadow-xl " src={imageUrl}/>}
            </div>
        </div>
    )
}

export function FeaturesSection() {

    return (
        <div id="features">
            <BaseFeatureSection
                title="Drag & drop to build 3D scenes."
                description='Build 3D scenes using hundreds of high-quality 3D assets and templates.'
                marginTop='0'
                iconUrl='https://ik.imagekit.io/remitnotpaucity/drag_drop_icon2_FXeZhOONd.png?ik-sdk-version=javascript-1.4.3&updatedAt=1658447428030'
                imageUrl='https://ik.imagekit.io/remitnotpaucity/drag_drop_image3_kIHttBaoP.png?ik-sdk-version=javascript-1.4.3&updatedAt=1658471135867'
            />
            <BaseFeatureSection
                title="Real-time cloud render."
                description='Tando streams ray-traced renderings to your screen in real-time. No GPU required to get stunning results.'
                iconUrl='https://ik.imagekit.io/remitnotpaucity/cloud_icon3_PnnEGsqTz.png?ik-sdk-version=javascript-1.4.3&updatedAt=1658547456680'
                imageUrl='https://ik.imagekit.io/remitnotpaucity/cloud_render2_m-LYqCxA3.png?ik-sdk-version=javascript-1.4.3&updatedAt=1658637780082'
            />
        </div>
    );
}