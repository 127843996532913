import React from 'react';
import { APP_MAX_WIDTH, EmailSubscribeStatus, MAILCHIMP_API_ENDPOINT } from '../../Constants';
import MailchimpSubscribe from "react-mailchimp-subscribe"



function updateImageWidth(imageContainerRef: React.RefObject<HTMLDivElement>) {
    if (imageContainerRef.current) {
        if (window.innerWidth > APP_MAX_WIDTH) {
            const scrollTop = window.pageYOffset || document.documentElement?.scrollTop || 0;
            const t = Math.min(scrollTop / window.innerHeight * 1.8, 1);
            const w = t * APP_MAX_WIDTH + (1 - t) * window.innerWidth;
            const r = t > 0.001 ? 1 : 0;
            imageContainerRef.current.style.width = `${w}px`;
            imageContainerRef.current.style.borderRadius = `${r}rem`;
        } else {
            imageContainerRef.current.style.width = '100%';
            imageContainerRef.current.style.borderRadius = '0rem'
        }
    }
}


function EmailSubscribe({
    subscribe,
    status: inputStatus,
    message,
}: {
    subscribe: (params: {EMAIL: string}) => void,
    status: EmailSubscribeStatus,
    message: string | Error | null,
}) {
    const [status, setStatus] = React.useState<EmailSubscribeStatus>(inputStatus);
    const emailInputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        setStatus(inputStatus);
    }, [inputStatus]);

    const buttonClassName = 
            status === 'error' 
                ? 'bg-red-500 hover:bg-red-600 border-red-500 focus:border-red-600' 
                : (status === 'success' ? 'bg-green-600 hover:bg-green-700 border-green-600 focus:border-green-700' : 'bg-blue-500 hover:bg-blue-600 border-blue-500 focus:border-blue-600');
    const buttonText = 
        status === 'error'
            ? (typeof(message) === 'string' ? message : 'Invalid email')
            : (status === 'success' ? 'Subscribed' : (status === 'sending' ? 'Sending ...' : 'Get early access'));

    return (
        <div
            className='flex-row justify-center items-center text-xl px-8'
        >
            <input 
                ref={emailInputRef}
                type="email"
                className='bg-slate-100 px-8 py-4 text-slate-500 rounded-xl focus:outline-none border-2 border-slate-100 focus:border-blue-500 transition-colors'
                placeholder='Your email here.'
                onChange={() => setStatus(null)}
            />
            <button 
                className={`${buttonClassName} cursor-pointer text-white px-8 py-4 ml-4 rounded-xl transition-colors border-2  focus:outline-none`}
                onClick={() => {
                    if (status !== 'success' && emailInputRef.current?.value) {
                        subscribe({
                            EMAIL: emailInputRef.current.value,
                        });
                    }
                }}
            >{buttonText}</button>
        </div>
    )
}

export function HeroSection() {
    const imageContainerRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        return window.addEventListener('scroll', () => {
            updateImageWidth(imageContainerRef);
        });
    }, []);

    React.useEffect(() => {
        return window.addEventListener('resize', () => {
            updateImageWidth(imageContainerRef);
        });
    }, []);

    return (
        <div 
            id="main"
            className='w-full flex flex-col h-screen items-center overflow-hidden'
        >
            <div className='z-10 mt-8 md:mt-12 2xl:mt-32 text-center'>
                <div 
                    className='text-4xl lg:text-5xl mb-8 font-semibold leading-snug md:leading-normal lg:leading-normal'
                >
                    Make stunning 3D designs <br className='hidden md:block'/> in the browser.
                </div>
                <div className='mx-4 text-lg md:text-xl text-slate-700 mb-12 leading-normal'>
                    Tando helps you create production-ready illustrations & motion-graphics in minutes.
                </div>
            </div>
            <div
                ref={imageContainerRef}
                className='absolute top-0 overflow-hidden z-0 h-screen bg-[center_top] 2xl:bg-center'
                style={{
                    width: '100%',
                    backgroundImage: 'url(https://ik.imagekit.io/remitnotpaucity/hero5_REsHudIlB.png?ik-sdk-version=javascript-1.4.3&updatedAt=1658713255403)',
                    backgroundSize: 'cover',
                }}
            />
        </div>
    )
}