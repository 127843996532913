import React from 'react';
import { Logo } from './Logo';

export default function Footer() {
    return (
        <div className='flex flex-row text-sm md:text-lg my-12 py-8'>
            <div className='flex-1 cursor-pointer h-6'  onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                <Logo height={22}/>
            </div>
            <div className='flex-1 flex flex-row justify-end'>
                <div className='text-slate-300'>
                    Copyright &copy; 2022 Tando Technologies Inc. All rights reserved.
                </div>
            </div>
        </div>
    )
}