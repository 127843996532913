import React from 'react';
import { EmailSubscribeStatus, MAILCHIMP_API_ENDPOINT, MOBILE_BREAKPOINT } from '../../Constants';
import MailchimpSubscribe from "react-mailchimp-subscribe";

function EmailSubscribeDesktop({
    subscribe,
    status: inputStatus,
    message,
}: {
    subscribe: (params: {EMAIL: string}) => void,
    status: EmailSubscribeStatus,
    message: string | Error | null,
}) {
    const [status, setStatus] = React.useState<EmailSubscribeStatus>(inputStatus);
    const emailInputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        setStatus(inputStatus);
    }, [inputStatus]);
    
    const buttonText = 
        status === 'error'
            ? (typeof(message) === 'string' ? message : 'Invalid email')
            : (status === 'success' ? 'Subscribed' : (status === 'sending' ? 'Sending' : 'Submit'));

    return (
        <div className='flex flex-col md:flex-row items-center my-2 md:my-8 w-full border-none md:border-solid md:border-b-4 border-blue-300'>
            <input
                ref={emailInputRef}
                type="email"
                className='flex-1 py-4 md:py-2 focus:outline-none border-none text-xl md:text-3xl lg:text-5xl text-blue-200 placeholder:text-blue-200 focus:text-white bg-transparent'
                placeholder='Your email here'
                onChange={() => setStatus(null)}
                onKeyDown={(e) => {
                    if (e.key?.toLowerCase() === 'enter' && status !== 'success' && emailInputRef.current?.value) {
                        subscribe({
                            EMAIL: emailInputRef.current.value,
                        });
                    }
                }}
            />
            <button
                className='text-xl md:text-3xl lg:text-5xl text-blue-200 hover:text-white cursor-pointer focus:outline-none focus:text-white'
                onClick={() => {
                    if (status !== 'success' && emailInputRef.current?.value) {
                        subscribe({
                            EMAIL: emailInputRef.current.value,
                        });
                    }
                }}
            >
                {buttonText}
            </button>
        </div>
    )
}

function SubscribeDesktop() {
    return (
        <div
            className='relative p-8 md:p-10 lg:p-12 bg-blue-500 rounded-3xl bg-no-repeat'
            style={{
                backgroundImage: 'url(https://ik.imagekit.io/remitnotpaucity/paperplane_background_J9w0I-iXf.png?ik-sdk-version=javascript-1.4.3&updatedAt=1658646592918)',
                backgroundSize: 'cover',
                backgroundPositionY: '-60px'
            }}
        >
            <div className='relative z-10'>
                <div className='text-2xl md:text-5xl lg:text-8xl text-white'>
                    Get early access
                </div>
                <MailchimpSubscribe
                    url={MAILCHIMP_API_ENDPOINT}
                    render={({subscribe, status, message}) => <EmailSubscribeDesktop subscribe={subscribe} status={status} message={message}/>}
                />
            </div>
            <img 
                alt="subscribePlane" 
                className='hidden md:block absolute z-50 pointer-events-none' 
                style={{
                    top: '-60px',
                    transform: 'rotate(-5deg)',
                }} 
                src="https://ik.imagekit.io/remitnotpaucity/paperplane_alpha_CiN3tsgUP.png?ik-sdk-version=javascript-1.4.3&updatedAt=1658648073687"/>
        </div>
    );
}function EmailSubscribeMobile({
    subscribe,
    status: inputStatus,
    message,
}: {
    subscribe: (params: {EMAIL: string}) => void,
    status: EmailSubscribeStatus,
    message: string | Error | null,
}) {
    const [status, setStatus] = React.useState<EmailSubscribeStatus>(inputStatus);
    const emailInputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        setStatus(inputStatus);
    }, [inputStatus]);

    const buttonClassName = 
            status === 'error' 
                ? 'bg-red-500 hover:bg-red-600 border-red-500 focus:border-red-600' 
                : (status === 'success' ? 'bg-green-500 border-green-500 focus:border-green-600' : 'bg-blue-500 hover:bg-blue-600 border-blue-500 focus:border-blue-600');
    
    const buttonText = 
        status === 'error'
            ? (typeof(message) === 'string' ? message : 'Invalid email')
            : (status === 'success' ? 'Subscribed' : (status === 'sending' ? 'Sending' : 'Subscribe'));

    return (
        <div className='flex flex-col items-center my-4 md:my-8 w-full border-none md:border-solid md:border-b-4 border-blue-300'>
            <input
                ref={emailInputRef}
                type="email"
                className='flex-1 w-full px-4 py-3 my-4 focus:outline-none border-none text-xl md:text-3xl lg:text-5xl text-slate-500 placeholder:text-slate-500 bg-white rounded-lg shadow-md'
                placeholder='Your email here'
                onChange={() => setStatus(null)}
                onKeyDown={(e) => {
                    if (e.key?.toLowerCase() === 'enter' && status !== 'success' && emailInputRef.current?.value) {
                        subscribe({
                            EMAIL: emailInputRef.current.value,
                        });
                    }
                }}
            />
            <button
                className={`${buttonClassName} w-full px-4 py-3 rounded-lg text-xl text-slate-100 hover:text-white cursor-pointer focus:outline-none bg-blue-500 shadow-md`}
                onClick={() => {
                    if (status !== 'success' && emailInputRef.current?.value) {
                        subscribe({
                            EMAIL: emailInputRef.current.value,
                        });
                    }
                }}
            >
                {buttonText}
            </button>
        </div>
    )
}

function SubscribeMobile() {
    return (
        <div

            className='relative p-8 md:p-10 lg:p-12 bg-blue-300 rounded-3xl'
        >
            <div className='flex-col justify-center items-center text-center'>
                <div className='text-4xl text-white'>
                    Get early access
                </div>
                <MailchimpSubscribe
                    url={MAILCHIMP_API_ENDPOINT}
                    render={({subscribe, status, message}) => <EmailSubscribeMobile subscribe={subscribe} status={status} message={message}/>}
                />
            </div>
        </div>
    );
}

export function SubscribeSection({
    width = MOBILE_BREAKPOINT + 1,
}: {
    width?: number,
}) {
    return (
        <div id="Subscribe">
            {width > MOBILE_BREAKPOINT ? <SubscribeDesktop/> : <SubscribeMobile/>}
        </div> 
    );
}