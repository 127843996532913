import React from 'react';

const aspectRatio = 601 / 166;

export function Logo({
    width=0,
    height=18,
    className='',
}: {
    width?: number,
    height?: number,
    className?: string,
}) {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 601 166"
            height="100%"
        >
            <path 
                id="tando" 
                style={{
                    fillRule: 'evenodd'
                }}
                d="M0,224.015c0,28.933,14.41,42.208,39.031,42.208,13.616,0,24.621-4.879,33.358-10.779L62.974,236.5c-6.467,4.084-14.523,7.375-21.9,7.375-9.531,0-17.246-5.673-17.246-20.31V176.247H65.47V154.689H23.829v-32.9H0V224.015ZM191.27,154.689H167.556V167.85c-7.489-9.644-18.721-16-34.153-16-30.408,0-54.348,23.941-54.348,57.186S103,266.223,133.4,266.223c15.432,0,26.664-6.24,34.153-15.885v13.048H191.27v-108.7ZM136.24,174.2c19.629,0,32.791,14.977,32.791,34.834s-13.162,34.833-32.791,34.833c-20.423,0-32.791-15.772-32.791-34.833S115.817,174.2,136.24,174.2Zm143.4-22.352c-11.119,0-24.621,3.29-33.358,16.112V154.689h-23.6v108.7h23.827V203.137c0-18.608,12.822-28.706,28.139-28.706,15.885,0,25.3,10.212,25.3,28.48v60.475h23.828v-68.3C323.783,169.666,305.515,151.852,279.645,151.852Zm121.961,0c-30.408,0-54.349,23.941-54.349,57.186s23.941,57.185,54.349,57.185c15.431,0,26.664-6.24,34.153-15.885v13.048h23.714V100H435.759V167.85C428.27,158.206,417.037,151.852,401.606,151.852Zm2.837,22.352c19.629,0,32.791,14.977,32.791,34.834s-13.162,34.833-32.791,34.833c-20.423,0-32.791-15.772-32.791-34.833S384.02,174.2,404.443,174.2Zm137.823-22.352c-33.585,0-58.434,24.622-58.434,57.186s24.849,57.185,58.434,57.185,58.547-24.621,58.547-57.185S575.851,151.852,542.266,151.852Zm0,22.352c19.4,0,34.152,14.3,34.152,34.834s-14.75,34.833-34.152,34.833-34.039-14.3-34.039-34.833S522.864,174.2,542.266,174.2Z" 
                transform="translate(0 -100)"
            />
        </svg>
    )
}